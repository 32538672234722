<template>
  <div class="releasing-block" v-if="releasingData._id">
    <h2 v-if="originalCustomer">Personligt erbjudande för din</h2>
    <h2 v-else>Personligt erbjudande på</h2>
    <h2 v-if="originalCustomer"> {{ releasingData.brandName }} {{ releasingData.modelName }} - {{ releasingData.registrationNumber }}
    </h2>
    <h2 v-else>{{ releasingData.brandName }} {{ releasingData.modelName }} {{ releasingData.engineName }}</h2>
    <div class="releasing-block__intro" v-if="originalCustomer">
      <p>Vi har tagit fram ett personligt erbjudande som gäller endast dig som idag kör {{
        releasingData.registrationNumber }}. Ett
        unikt erbjudande där du kan välja att förlänga din privatleasing med fördelaktiga villkor eller att köpa ut din
        bil till ett extra förmånligt pris.</p>
      <p class="text_danger h4">Erbjudandet gäller t.o.m. {{ releasingData.offerEndDate }}</p>
    </div>
    <div class="releasing-block__intro" v-else>
      <p>Årsmodell: {{ releasingData.modelYear }} | Färg: {{ releasingData.baseColorName }} | Tillgänglig: {{
        releasingData.endDate }}</p>
      <p>Vi har beräknat ett personligt erbjudande för en {{ releasingData.brandName }} {{ releasingData.modelName }} –
        skräddarsytt för just dig.
        Detta unika erbjudande ger dig möjlighet att välja mellan att privatleasa bilen under förmånliga villkor eller
        att köpa den till ett förmånligt pris.</p>

      <p class="text_danger h4">Erbjudandet gäller t.o.m. {{ releasingData.offerEndDate }} eller tills bilen är såld</p>
    </div>

    <div class="releasing-block__body">
      <div class="releasing-block__section" v-if="releasingData.installmentMonthlyCost && releasingData.installmentMonthlyCost > 0">
        <div class="releasing-block__section-header">
          <h4 v-if="originalCustomer">Köp din {{ releasingData.registrationNumber }} för endast</h4>
          <h4 v-else>Köp {{ releasingData.brandName }} {{ releasingData.modelName }} för endast</h4>
          <div class="releasing-block__offer h1">
            {{ $formatPriceNoSuffix(releasingData.installmentMonthlyCost) }} kr/mån*
          </div>
          <p>Ditt personliga pris {{ $formatPriceNoSuffix(releasingData.currentPrice) }} kr</p>
        </div>
        <div class="releasing-block__section-footer">
          <h3>Avbetalning</h3>
          <table>
            <tr>
              <td><strong v-if="originalCustomer">Ny månadskostnad</strong><strong v-else>Månadskostnad</strong></td>
              <td><strong>{{ $formatPriceNoSuffix(releasingData.installmentMonthlyCost) }} kr/mån*</strong></td>
            </tr>
            <tr>
              <td>Avtalsperiod</td>
              <td>{{ releasingData.installmentPeriod }} mån</td>
            </tr>
            <tr>
              <td>Ränta</td>
              <td>{{ releasingData.rateText }}</td>
            </tr>
            <tr>
              <td>Kontantinsats kr</td>
              <td>{{ $formatPriceNoSuffix(releasingData.downPayment) }} kr</td>
            </tr>
            <tr>
              <td>Ditt personliga pris</td>
              <td>{{ $formatPriceNoSuffix(releasingData.currentPrice) }} kr</td>
            </tr>
          </table>

          <div class="form-text">* Räntan är rörlig och kan komma att påverkas både upp och ned. Priset är räknat på 20%
            kontantinsats och {{ releasingData.residualValueText }} restvärde.</div>
        </div>
      </div>
      <div class="releasing-block__section" v-if="releasingData.privateLeasingMonthlyCost && releasingData.privateLeasingMonthlyCost > 0">
        <div class="releasing-block__section-header">
          <h4 v-if="originalCustomer">Förläng din privatleasing för endast</h4>
          <h4 v-else>Privatleasa {{ releasingData.brandName }} {{ releasingData.modelName }} för endast</h4>
          <div class="releasing-block__offer h1">
            {{ $formatPriceNoSuffix(releasingData.privateLeasingMonthlyCost) }} kr/mån**
          </div>
          <p>Kontantinsats: 0 kr</p>
        </div>
        <div class="releasing-block__section-footer">
          <h3 v-if="originalCustomer">Förläng din privatleasing</h3>
          <h3 v-else>Privatleasing</h3>
          <table>
            <tr>
              <td><strong v-if="originalCustomer">Ny månadskostnad</strong><strong v-else>Månadskostnad</strong></td>
              <td><strong>{{ $formatPriceNoSuffix(releasingData.privateLeasingMonthlyCost) }} kr/mån**</strong></td>
            </tr>
            <tr>
              <td>Avtalsperiod</td>
              <td>{{ releasingData.privateLeasingPeriod }} mån</td>
            </tr>
            <tr>
              <td>Mil</td>
              <td>{{ releasingData.privateLeasingMileage ? $formatPriceNoSuffix(releasingData.privateLeasingMileage) :
                $formatPriceNoSuffix(2000) }} mil</td>
            </tr>
            <tr>
              <td>Kontantinsats</td>
              <td>0 kr</td>
            </tr>
            <tr>
              <td>Serviceavtal</td>
              <td>Ingår</td>
            </tr>
          </table>
          <div class="form-text">** Leasingavgiften är rörlig och kan påverkas om t.ex. räntan för leasegivarens
            refinansiering eller dess upplåningskostnader förändras. Priserna är frånpriser med {{
              releasingData.privateLeasingMileage ? releasingData.privateLeasingMileage /
                (releasingData.privateLeasingPeriod / 12) : '1000' }} mil/år.</div>
        </div>
      </div>
    </div>
    <div class="section section--gray">
      <div class="releasing-block__footer">
        <div class="releasing-block__footer__body">
          <div class="releasing-block__form" v-if="!formSent">
            <form @submit.prevent="sendForm" class="">
              <h3>Ta del av ditt personliga erbjudande</h3>
              <p>Fyll i formuläret nedan så kontaktar en säljare dig inom kort.</p>
              <label>
                Vilket erbjudande är du intresserad av?
              </label>
              <div class="form-row" v-if="releasingData.installmentMonthlyCost && releasingData.installmentMonthlyCost > 0">
                <div class="form-row__col">
                  <div class="form-check form-check--sm form-check--inline">
                    <input id="installmentSelection" name="offerSelection" v-model="offerSelection"
                      class="form-check__input" type="radio" value="avbetalning" />
                    <label class="form-check__label" for="installmentSelection">
                      Jag vill köpa bilen
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-row" v-if="releasingData.privateLeasingMonthlyCost && releasingData.privateLeasingMonthlyCost > 0">
                <div class="form-row__col">
                  <div class="form-check form-check--sm form-check--inline">
                    <input id="releasingSelection" name="offerSelection" v-model="offerSelection"
                      class="form-check__input" type="radio" value="releasing" />
                    <label v-if="originalCustomer" class="form-check__label" for="releasingSelection">
                      Jag vill förlänga min privatleasing
                    </label>
                    <label v-else class="form-check__label" for="releasingSelection">
                      Jag teckna privatleasing
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-row__col">
                  <div class="form-stack form-stack--required">
                    <label>
                      Namn
                    </label>
                    <input v-model="inputName" class="form-control" type="text" autocomplete="name"
                      placeholder="Ditt namn" required>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-row__col">
                  <div class="form-stack form-stack--required">
                    <label>
                      E-post
                    </label>
                    <input v-model="inputEmail" class="form-control" type="email" autocomplete="email"
                      placeholder="Din e-postadress" required>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-row__col">
                  <div class="form-stack form-stack--required">
                    <label>
                      Mobilnummer
                    </label>
                    <input v-model="inputPhone" class="form-control" type="tel" placeholder="Ditt telefonnummer"
                      required autocomplete="tel">
                  </div>
                </div>
              </div>

              <div class="form-row" v-if="!originalCustomer">
                <div class="form-row__col">
                  <div class="form-stack form-stack--required">
                    <label>
                      Närmaste anläggning
                    </label>
                    <div class="form-select">
                      <select class="form-control" v-model="selectedLocation">
                        <option value="">
                          Välj ort
                        </option>
                        <option v-for="location in locations" :value="location" :key="location">
                          {{ location }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-row__col">
                  <div class="form-check form-check--sm form-check--inline">
                    <input id="acceptedTerms" v-model="acceptedTerms" class="form-check__input" type="checkbox" />
                    <label class="form-check__label" for="acceptedTerms">
                      Jag godkänner att Holmgrens Bil behandlar mina uppgifter som en del i hanteringen av detta ärende.
                    </label>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-row__col">
                  <button :disabled="!acceptedTerms || sending" class="btn btn--primary btn--block"
                    type="submit">Skicka</button>
                </div>
              </div>
              <div class="form-text">Alla personuppgifter som skickas in till
                Holmgrens Bil kommer att behandlas enligt bestämmelserna i EU:s dataskyddsförordningen (GDPR). <a
                  href="https://www.holmgrensbil.se/om-oss/personuppgiftspolicy" target="_blank">Här</a> kan du läsa
                mer om hur vi behandlar dina personuppgifter.</div>
            </form>
          </div>
          <div class="releasing-block__text" v-else>
            <VcFeedbackMessage success>
              <div slot="header">
                Tack för ditt svar!
              </div>
              En säljare kommer kontakta dig för vidare behandling av ditt ärende.
            </VcFeedbackMessage>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from '../../axios';
import { VcFeedbackMessage } from '@holmgrensbil/vue-components';

export default {
  components: {
    VcFeedbackMessage,
  },
  props: {
    originalCustomer: { type: Boolean, default: false }
  },
  data() {
    return {
      releasingData: {},
      inputName: "",
      inputEmail: "",
      inputPhone: "",
      acceptedTerms: false,
      offerSelection: "avbetalning",
      id: "",
      sending: false,
      formSent: false,
      selectedLocation: "",
      locations: [
        "Jönköping",
        "Kalmar",
        "Karlskrona",
        "Linköping",
        "Norrköping",
        "Nyköping",
        "Nässjö",
        "Skövde",
        "Vetlanda",
        "Vimmerby",
        "Värnamo",
        "Växjö",
        "Örebro"
      ]
    };
  },
  async mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    this.id = urlParams.get("qv");
    if (this.id && this.id.length > 0) {
      await this.fetchReleasingData();
    }
  },
  methods: {
    async fetchReleasingData() {
      try {
        let url = `https://haapi.holmgrensbil.se/rest/apps/PrivateLeasing/searchers/privateleasingreturncustomer?q=${this.id}`;
        if (!this.originalCustomer) {
          url = `https://haapi.holmgrensbil.se/rest/apps/PrivateLeasing/searchers/privateleasingreturngeneric?q=${this.id}`
        }
        const { data } = await Axios.get(url);
        this.releasingData = data.documents.documents[0];
      } catch (e) {
        console.warn(e);
      }
    },
    async sendForm() {
      if (!this.inputEmail || !this.inputPhone || !this.acceptedTerms) {
        return false;
      }
      try {
        this.sending = true;
        var data = {
          registrationNumber: this.releasingData.registrationNumber,
          selectedOffer: this.offerSelection,
          customerName: this.inputName,
          customerEmail: this.inputEmail,
          customerPhone: this.inputPhone,
          genericCustomer: !this.originalCustomer,
          departmentCity: this.selectedLocation,
          vehicleId: this.releasingData._id
        }
        const { result } = await Axios.post("/api/Contact/SendReleasingForm", data);
        this.formSent = true;
      } catch (e) {
        console.warn(e);
        this.sending = false;
      }
    }
  },
};
</script>
